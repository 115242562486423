<template>
  <div>
    <v-card>
      <v-card-text>
        <router-link :to="{ name: 'visas-index' }"><img width="150px" :src="showLogoCompany()" /></router-link>
        <span class="float-right">Date : {{ timestamp | date }} </span>
        <br /><br />
        <!-- <v-container> -->
        <v-row no-gutters>
          <v-col cols="6" sm="6" md="6">
            <strong> FROM : </strong><br /><br />
            <div style="font-size: 13px" v-for="pm in companys" :key="pm.id_pemilik">
              <strong>{{ pm.nama_perusahaan }}</strong
              ><br />
              {{ pm.alamat_perusahaan }}<br />
              Phone: {{ pm.telp_01 }}, {{ pm.telp_02 }}, {{ pm.telp_03 }}, {{ pm.telp_04 }}<br />
              Email: {{ pm.email }} <br />
              Website: www.expatservicebali.com
            </div>
          </v-col>
          <v-col>
            <strong> TO : </strong><br /><br />
            <div style="font-size: 13px">
              <strong
                >{{ visa.nama_depan === null || visa.nama_depan === undefined ? '' : visa.nama_depan }}
                {{ visa.nama_belakang === null || visa.nama_belakang === undefined ? '' : visa.nama_belakang }}</strong
              ><br />
              {{
                visa.alamat_diindonesia === null || visa.alamat_diindonesia === undefined
                  ? ''
                  : visa.alamat_diindonesia
              }}<br />
              Phone: {{ visa.no_telp }}<br />
              Email: {{ visa.email }}<br />
            </div>
          </v-col>
          <v-col cols="3" sm="3" md="3">
            <b>Payment Due:</b>
            {{ visa.tgl_bayar | date }}<br />
            <b>Account:</b> {{ visa.nomor_aplikasi }}<br />
            <span v-if="visa.nomor_aplikasi">
              <vue-qrcode v-bind:value="visa.nomor_aplikasi" />
            </span>
          </v-col>
        </v-row>
        <!-- </v-container> -->
        <strong>(IDR)</strong><br /><br />
        <v-simple-table dense>
          <!-- <template v-slot:default> -->
          <thead>
            <tr>
              <th style="font-size: 11px" class="text-left">NO</th>
              <th style="font-size: 11px" class="text-left">DESCRIPTION</th>
              <th style="font-size: 11px" class="text-left">AMOUNT</th>
            </tr>
          </thead>
          <tbody v-if="endprice_biaya != 0">
            <tr v-for="(hb, index) in biaya" :key="index">
              <td style="font-size: 11px">{{ index + 1 }}</td>
              <td style="font-size: 11px">{{ hb.jenis_biaya }}</td>
              <td style="font-size: 11px">{{ hb.harga | currency }}</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td style="font-size: 12px">
                <b>TOTAL </b>
              </td>
              <td style="font-size: 12px" v-for="(tb, index) in totalbiaya" :key="index">
                <strong>{{ tb.total_biaya | currency }}</strong>
              </td>
            </tr>
          </tbody>
          <!-- </template> -->
        </v-simple-table>
        <hr />
        <strong>(USD)</strong><br /><br />
        <v-simple-table dense>
          <template>
            <thead>
              <tr>
                <th style="font-size: 11px" class="text-left">NO</th>
                <th class="text-left">DESCRIPTION</th>
                <th class="text-left">AMOUNT</th>
              </tr>
            </thead>
            <tbody v-if="endprice_biaya != 0">
              <tr v-for="(hb, index) in biayadollar" :key="index">
                <td style="font-size: 11px">{{ index + 1 }}</td>
                <td style="font-size: 11px">{{ hb.jenis_biaya }}</td>
                <td style="font-size: 11px">{{ hb.harga | currency }}</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td style="font-size: 12px">
                  <b>TOTAL </b>
                </td>
                <td style="font-size: 12px" v-for="(tb, index) in totalbiayadollar" :key="index">
                  <strong>{{ tb.total_biaya_dollar | dollar_currency }}</strong>
                </td>
              </tr>
            </tbody>
          </template> </v-simple-table
        ><br /><br />
        <img style="position: absolute; left: 850px" width="170px" :src="showLogoUnpaid()" />
        <v-row no-gutters>
          <v-col cols="6" sm="6" md="6" style="padding-right: 10px">
            <strong> PAYMENT METHODS : </strong><br /><br />
            <h6>IDR ACCOUNT</h6>
            <strong>Beneficiary Bank Acc No :</strong>
            1461418878
            <br />
            <strong>Beneficiary Bank Name :</strong> BANK CENTRAL ASIA
            <br />
            <strong>Bank Address :</strong> SUNSET ROAD, KUTA
            <br />
            <strong>Beneficiary Full Name :</strong> PT. BALI SOLUSI PERSADA
            <br />
            <strong>Office Address :</strong> JL. SUNSET ROAD NO.261, SEMINYAK, BALI-INDONESIA, 80361
            <br />
            <strong>Swift Code :</strong> CENAIDJA <br />
          </v-col>
          <v-col cols="6" sm="6" md="6">
            <strong> &nbsp; </strong><br /><br />
            <h6>USD ACCOUNT</h6>
            <strong>Beneficiary Bank Acc No :</strong>
            1462761761
            <br />
            <strong>Beneficiary Bank Name :</strong> BANK CENTRAL ASIA
            <br />
            <strong>Bank Address :</strong> SUNSET ROAD, KUTA
            <br />
            <strong>Beneficiary Full Name :</strong> PT. BALI SOLUSI PERSADA
            <br />
            <strong>Office Address :</strong> JL. SUNSET ROAD NO.261, SEMINYAK, BALI-INDONESIA, 80361
            <br />
            <strong>Swift Code :</strong> CENAIDJA <br />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import VueQrcode from 'vue-qrcode'
export default {
  components: {
    VueQrcode,
  },
  data() {
    return {
      companys: [],
      datatransaksi: [],
      visa: [],
      biaya: [],
      biayadollar: [],
      totalbiaya: [],
      totalbiayadollar: [],
    }
  },
  created() {
    this.$isLoading(true)
    setTimeout(() => {
      this.$isLoading(false)
      this.getDataVisa()
      this.getDataBiayaTransaksi()
      this.getDataCompany()
      this.showLogoCompany()
      this.showLogoUnpaid()
    }, 2000)
  },
  methods: {
    getDataBiayaTransaksi() {
      //state token
      localStorage.getItem('token')
      let uriBiayaTransaksi =
        process.env.VUE_APP_ROOT_API + `/api/transaksis/${this.$route.params.id_transaksi}/biayainvoice`
      return this.axios.get(uriBiayaTransaksi).then(response => {
        this.biaya = response.data.datatransaksiidr
        this.totalbiaya = response.data.totaltransaksiidr
        this.biayadollar = response.data.datatransaksidollar.filter(bd => bd.jenis_biaya != undefined)
        this.totalbiayadollar = response.data.totaltransaksidollar
        setTimeout(() => {
          window.print()
        }, 1000)
      })
    },

    getDataVisa() {
      //state token
      localStorage.getItem('token')
      let uriTagihan = process.env.VUE_APP_ROOT_API + `/api/visas/${this.$route.params.id_transaksi}/invoice`
      return this.axios.get(uriTagihan).then(response => {
        this.visa = response.data.datavisa
        this.datatransaksi = response.data.datatransaksis
        console.log('VISA : ' + this.visa)
      })
    },
    getDataCompany() {
      //state token
      localStorage.getItem('token')

      let url = process.env.VUE_APP_ROOT_API + '/api/pemiliks'
      return this.axios.get(url).then(response => {
        this.companys = response.data.pemilik
        console.log('LOGO :' + this.companys[0].logo_perusahaan)
      })
    },
    showLogoUnpaid() {
      let ImageUnpaid = process.env.VUE_APP_ROOT_API + `/images/visa/paid.png`
      return ImageUnpaid
    },
    showLogoCompany() {
      //state token
      localStorage.getItem('token')
      if (this.companys[0].logo_perusahaan.length != 0) {
        if (this.companys[0].logo_perusahaan.length > 100) {
          return this.companys[0].logo_perusahaan
        }
        let imageClients = process.env.VUE_APP_ROOT_API + `/images/logo_perusahaan/${this.companys[0].logo_perusahaan}`
        return imageClients
      }
      let imageDefault = process.env.VUE_APP_ROOT_API + `/images/logo_perusahaan/no-images.png`
      return imageDefault
    },
  },
}
</script>
